<template>
  <v-sheet 
    color="transparent" 
    :class="`overflow-x-hidden title-ellipse poppins fw600 ${font} ${color}--text`" 
    :max-width="width" 
  >
    {{ title }}
  </v-sheet>
</template>

<script>
export default {
  props: ['title', 'width', 'font', 'color']
}
</script>